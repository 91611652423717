import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs,
  Alert,
} from "@mui/material";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate, Link } from "react-router-dom";
import { useDeleteEntitlementMutation } from "../../../features/entitlements/entitlementsApiSlice";
import Spinner from "../../global/Spinner";
import DeleteDialog from "../shared/DeleteDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";
import FilterDropDown from "../shared/FilterDropDown";
import { useFetchEntitlementsMutation } from "../../../features/entitlements/entitlementsApiSlice";

const Entitlements = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(
    (permission) => permission.name === "entitlement-add"
  );
  const canEdit = currentPermissions.some(
    (permission) => permission.name === "entitlement-update"
  );
  const canDelete = currentPermissions.some(
    (permission) => permission.name === "entitlement-delete"
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);

  const [selectedEntitlement, setSelectedEntitlment] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [fetchEntitlements] = useFetchEntitlementsMutation();

  const [entitlements, setEntitlements] = useState([]);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  // Function to fetch entitlements manually
  const fetchEntitlementsData = async () => {
    setIsLoading(true);
    setIsFetching(true);
    try {
      const response = await fetchEntitlements({
        page: pagination.currentPage,
        pageSize: pagination.perPage,
        ...getApiFilters(),
      }).unwrap();

      setEntitlements(response.data);

      // Update pagination state from response
      setPagination((prev) => ({
        ...prev,
        currentPage: response.meta.current_page,
        lastPage: response.meta.last_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
      }));

      setIsSuccess(true);
      setIsFetching(true);
    } catch (error) {
      console.error("Error fetching entitlements:", error);
      setIsSuccess(false);
      setIsFetching(false);
    } finally {
      setIsLoading(false);
      setIsFetching(false);
    }
  };

  // Effect to trigger the fetch when needed
  useEffect(() => {
    fetchEntitlementsData();
  }, [pagination.currentPage, pagination.perPage, filterModel]);

  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const [deleteEntitlement] = useDeleteEntitlementMutation();

  // Handle page change event from DataGrid
  const handlePageChange = (newPage) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: newPage + 1, // Set to the new page (add 1 since DataGrid pages are zero-based)
    }));
  };

  // Function to handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setPagination((prev) => ({
      ...prev,
      perPage: newPageSize, // Update the page size
      currentPage: 1, // Reset to the first page whenever the page size changes
    }));
  };

  const handleEdit = (entitlement) => {
    entitlement = JSON.parse(entitlement);
    navigate(`/interventions/entitlements/edit/${entitlement.id}`, {
      state: { entitlement },
    });
  };

  const handleClickOpen = (entitlement) => {
    setSelectedEntitlment(entitlement);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedEntitlment(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const getEntitlement = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              {canEdit && (
                <Button
                  color="secondary"
                  onClick={() => handleEdit(getEntitlement())}
                >
                  {t("global.table.edit_button")} <EditOutlinedIcon />
                </Button>
              )}

              {canDelete && (
                <Button
                  color="error"
                  onClick={() => handleClickOpen(getEntitlement())}
                >
                  {t("global.table.delete_button")}{" "}
                  <DeleteOutlineOutlinedIcon />
                </Button>
              )}
            </ButtonGroup>
          </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t("global.table.name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.table.description"),
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "amount",
    //   headerName: t("entitlements.form.label.amount"),
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "unit",
      headerName: t("entitlements.form.label.unit"),
      flex: 1,
      minWidth: 100,
    },
    // {
    //   field: "max_redeemable",
    //   headerName: t("entitlements.form.label.max_redeemable"),
    //   flex: 1,
    //   minWidth: 125,
    // }
  ];
  const [result, setResult] = useState(null);

  const handleSubmit = (selectedData) => {
    setResult(selectedData);
  };

  const handleEntitlementsResponse = (response) => {
    // Handle the response from fetchEntitlements here
    setEntitlements(response);
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("entitlements.index.title")}
        subtitle={t("entitlements.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Entitlements")}
        </Typography>
      </Breadcrumbs>

      {canAdd && (
        <Box display="flex" gap="20px" justifyContent="end" mt="20px">
          <FilterDropDown
            dropDownTitle="Select Filters"
            buttonTitle="Check Duplicates"
            onSubmit={handleSubmit}
            onResponse={handleEntitlementsResponse}
          />
          <Link to="/interventions/entitlements/add" underline="none">
            <button className="btn btn-primary">
              {t("entitlements.index.add_button")}
            </button>
          </Link>
        </Box>
      )}

      <Box className="data_grid_box_container">
        {entitlements?.length < 1 ? (
          <Alert severity="info">
            {t("entitlements.index.no_entitlements_data")}
          </Alert>
        ) : (
          <StyledDataGrid
            rows={entitlements}
            columns={columns}
            pagination
            pageSize={pagination.perPage}
            rowCount={pagination.total}
            page={pagination.currentPage - 1}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            loading={isFetching}
            paginationMode="server"
            onFilterModelChange={handleFilterModelChange}
            rowsPerPageOptions={default_rows_per_page_options}
          />
        )}
      </Box>
      {openDialog && (
        <DeleteDialog
          open={openDialog}
          onClose={handleClose}
          name={JSON.parse(selectedEntitlement).title}
          deleteMethod={deleteEntitlement}
          url={`/entitlement/delete/${JSON.parse(selectedEntitlement).id}`}
        />
      )}
    </Box>
  );

  return content;
};

export default Entitlements;
