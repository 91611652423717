import React, { useState, useRef, useEffect } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { MdFilterAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Box, TextField, MenuItem } from "@mui/material";
import { Formik } from "formik";
import Toast from "../../global/Toast";

import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import { useGetPackagesQuery } from "../../../features/packages/packagesApiSlice";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import { useFetchEntitlementsMutation } from "../../../features/entitlements/entitlementsApiSlice";

export default function FilterDropDown({
  dropDownTitle,
  onSubmit,
  onResponse,
}) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(true);
  };

  const [fetchEntitlements] = useFetchEntitlementsMutation();

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
    };

    setIsSubmitting(true);

    try {
      const response = await fetchEntitlements({
        payload,
        page,
        pageSize,
      }).unwrap();

      if (response?.data) {
        toast(response?.message, "success");
        onResponse(response?.data);
        setIsSubmitting(false);
        handleToggle();
      } else {
        toast(response?.message, "error");
        setIsSubmitting(false);
        handleToggle();
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
      setIsSubmitting(false);
      handleToggle();
    }
  };

  // the interventions
  const [interventions, setInterventions] = useState([]);
  const [filteredInterventions, setFilteredInterventions] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [interventionId, setInterventionId] = useState("");
  const [packageId, setPackageId] = useState("");

  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);

  // Fetch Interventions Data
  const {
    data: iData,
    isSuccess: fetchingInterventionSuccess,
    isLoading: fetchingInterventions,
  } = useGetInterventionsQuery({
    page: 1,
    pageSize: 100,
  });

  // Fetch Packages Data
  const {
    data: pData,
    isSuccess: fetchingPackagesSuccess,
    isLoading: fetchingPackages,
  } = useGetPackagesQuery({
    page: 1,
    pageSize: 100,
  });

  // Update Interventions on Successful Fetch
  useEffect(() => {
    if (fetchingInterventionSuccess && iData?.data) {
      setInterventions(iData.data);
    }
  }, [fetchingInterventionSuccess, iData]);

  // Update Filtered Interventions on Project ID Change
  useEffect(() => {
    if (projectId) {
      filterInterventionsByProject();
    } else {
      setFilteredInterventions([]);
    }
  }, [projectId, interventions]);

  // Update Packages on Successful Fetch
  useEffect(() => {
    if (fetchingPackagesSuccess && pData?.data) {
      setPackages(pData.data);
    }
  }, [fetchingPackagesSuccess, pData]);

  // Update Filtered Packages on Intervention ID Change
  useEffect(() => {
    if (interventionId) {
      filterPackagesByIntervention();
    } else {
      setFilteredPackages([]);
    }
  }, [interventionId, packages]);

  // Filter Interventions by Project ID
  const filterInterventionsByProject = () => {
    setFilteredInterventions(interventions);
  };

  // Filter Packages by Intervention ID
  const filterPackagesByIntervention = () => {
    setFilteredPackages(
      packages.filter(
        (packageData) => packageData.intervention.id === interventionId
      )
    );
  };

  // Handle Project ID Change
  const handleProjectId = (event) => {
    const newProjectId = event.target.value;
    setProjectId(newProjectId);
  };

  // Handle Intervention ID Change
  const handleInterventionId = (event) => {
    const newInterventionId = event.target.value;
    setInterventionId(newInterventionId);
  };

  //   Handle package Id change
  const handlePackageId = (event) => {
    const newPackageId = event.target.value;
    setPackageId(newPackageId);
  };

  const {
    data: projectData,
    isSuccess: fetchingProjectsSuccess,
    isLoading: fetchingProjects,
  } = useGetProjectsQuery({
    page: 1,
    pageSize: 100,
  });

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (projectData?.data) setProjects(projectData.data);
  }, [fetchingProjectsSuccess, projectData]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    project_id: "",
    intervention_id: "",
    package_id: "",
  };

  return (
    <div className="border_styles">
      <div className="border_lines_styles"></div>
      {/* <div className="border_text_styles">{borderTitle}</div> */}
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <button className="btn btn-primary" onClick={handleToggle}>
          <MdFilterAlt />
          {dropDownTitle}
        </button>
      </ButtonGroup>

      <Popper
        sx={{ zIndex: 1, padding: "25px" }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper
              sx={{
                width: "220px",
                boxShadow: 10,
                borderRadius: 4,
                padding: "20px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box display="grid" gap="20px">
                        {fetchingProjects && fetchingProjectsSuccess ? (
                          <p>Loading Projects...</p>
                        ) : (
                          <TextField
                            select
                            variant="filled"
                            label={t("payment.create.choose_project")}
                            name="project_id"
                            value={values.project_id}
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event);
                              handleProjectId(event);
                            }}
                          >
                            {projects.map((project) => (
                              <MenuItem key={project.id} value={project.id}>
                                {project.title}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                        {projectId != null &&
                          projectId !== "" &&
                          (fetchingInterventions ? (
                            <p>Loading Interventions...</p>
                          ) : (
                            <TextField
                              select
                              variant="filled"
                              label={t("payment.create.choose_intervention")}
                              name="intervention_id"
                              value={values.intervention_id}
                              onBlur={handleBlur}
                              onChange={(event) => {
                                handleChange(event);
                                handleInterventionId(event);
                              }}
                            >
                              {filteredInterventions.map((intervention) => (
                                <MenuItem
                                  key={intervention.id}
                                  value={intervention.id}
                                >
                                  {intervention.title}
                                </MenuItem>
                              ))}
                            </TextField>
                          ))}

                        {interventionId != null &&
                          interventionId !== "" &&
                          (fetchingPackages ? (
                            <p>Loading Packages...</p>
                          ) : (
                            <TextField
                              select
                              variant="filled"
                              label={t("payment.create.choose_package")}
                              name="package_id"
                              value={values.package_id}
                              onBlur={handleBlur}
                              onChange={(event) => {
                                handleChange(event);
                                handlePackageId(event);
                              }}
                            >
                              {filteredPackages.map((packageData) => (
                                <MenuItem
                                  key={packageData.id}
                                  value={packageData.id}
                                >
                                  {packageData.title}
                                </MenuItem>
                              ))}
                            </TextField>
                          ))}
                      </Box>

                      <hr />
                      <Box display="flex" justifyContent="center" mt="20px">
                        <button
                          type="submit"
                          className="btn btn-primary btn-full"
                          disabled={isSubmitting}
                        >
                          {isSubmitting
                            ? "Submitting ..."
                            : t("global.table.filter_button")}
                        </button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
